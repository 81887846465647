import * as THREE from "three";
import AgoraRTC, { IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { v4 } from 'uuid'
import AgoraRTM, { RtmChannel, RtmClient, RtmMessage } from "agora-rtm-sdk";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { PositionalAudioHelper } from "three/examples/jsm/helpers/PositionalAudioHelper";
import { RAYCAST_EXCLUDE_LAYER } from "../core/Scene";
import { makeAutoObservable } from "mobx";

async function QueueWorker(task: Function) {
    return await task();
}
const queue = require("fastq").promise(QueueWorker, 1);


AgoraRTC.setLogLevel(2);

const appId = "b788346730fa4d65b529a644ee50b320";
const channelMemberLimit = 64;//THIS IS RTM LIMIT
//WE CANNOT SET A LIMIT FOR RTC FOR NOW - MAYBE ASK AGORA
//FOR NOW WE ARE LIMITING PEOPLE IN SQUARE BY GEOMETRY
export type Cell = [number, number];

export type MessageType = "INIT" | "POSITION" | "ROTATION" | "CUSTOM"

export interface Message {
    type: MessageType,
    data: any
}

export interface RemoteUser {
    uid: string,
    initialised?: boolean
    object: THREE.Object3D;
    targetPosition?: THREE.Vector3,
    targetRotation?: THREE.Quaternion,
    rtcVideoSubscribedChannel?: string
    rtcAudioSubscribedChannel?: string
}

export default class MultiplayerClient {

    scene: any;

    options = {
        positionalAudioEnabled: true,
        positionalAudioHelperEnabled: false
    }

    users: Map<string, RemoteUser> = new Map<string, RemoteUser>();
    customEventHandlerMap: Map<string, any> = new Map<string, any>();

    rtm_client: RtmClient | null = null;
    rtm_channel: RtmChannel | null = null;
    rtm_joined: boolean = false;

    localMicrophone: IMicrophoneAudioTrack | undefined;
    localVideo: ICameraVideoTrack | undefined;

    cellSize = 6;

    currentCellMat = new THREE.MeshBasicMaterial({ color: 'red', side: THREE.DoubleSide })
    surroundingCellMat = new THREE.MeshBasicMaterial({ color: 'green' })

    prevCell: Cell = [0, 0]
    currentCell: Cell = [0, 0]

    connections: Map<string, IAgoraRTCClient> = new Map();

    uid: string;
    meetingId: string | undefined;

    localVideoView: HTMLVideoElement | null = null
    joined = false;

    private lastUpdateTime = -1;
    private updateInterval: number = -1;

    video_muted: boolean = false;
    //quick fix to mute on start
    audio_muted: boolean = false;

    constructor(scene: any) {
        makeAutoObservable(this);
        this.scene = scene;
        this.generateDebugCells();
        this.uid = v4();
        window.requestAnimationFrame(this.updatePositions)
    }

    objects: THREE.Mesh[] = []

    join = async (meetingId: string, cameraId?: string, microphoneId?: string) => {
        this.meetingId = meetingId;
        await this.setupLocalTracks(cameraId, microphoneId);

        // Start RTM init
        this.rtm_client = AgoraRTM.createInstance(appId, {
            enableLogUpload: false,
            logFilter: AgoraRTM.LOG_FILTER_ERROR,
        });

        await this.rtm_client.login({ uid: this.uid });
        console.log("rtm_client login success.");

        const channelCountResult = await this.rtm_client.getChannelMemberCount([meetingId])
        if(channelCountResult[meetingId] >= channelMemberLimit){
            alert("Channel limit reached! - please go to our overflow site: festiverse2.zonevs.io")
            throw new Error("Channel limit reached!")
        }

        this.rtm_channel = this.rtm_client.createChannel(meetingId);
        this.handleRtmEvents();

        await this.rtm_channel.join();
        console.log("rtm_channel join success.");
        this.rtm_joined = true;
        // End RTM init
        this.updateInterval = window.setInterval(this.update, 5000);

        this.joined = true;
    }

    leave = async () => {
        //Leave RTC
        try {
            this.localMicrophone?.stop();
            this.localMicrophone?.close();

            this.localVideo?.stop();
            this.localVideo?.close();

            this.connections.forEach((connection) => {
                connection.remoteUsers.forEach((user) => {
                    try {
                        document.getElementById("face-video-" + user.uid)?.remove();
                    }catch (e) {}
                })
                connection.leave();
            })
        } catch (e) {
            console.log("Error in rtc_client leave.");
            console.error(e);
        }

        //Leave RTM
        try {
            await this.rtm_client?.logout();
            this.rtm_channel = null;
            this.rtm_client = null;
            console.info("RTM leave success.");
        } catch (err) {
            console.info("RTM leave failure.");
            console.error(err);
        }

        //Cleanup user models
        for (const [, user] of this.users.entries()) {
            this.scene.scene.remove(user.object);
        }

        this.users = new Map<string, RemoteUser>()
    }

    setupLocalTracks = async (cameraId?: string, microphoneId?: string) => {
        let selectedCamera= cameraId;
        let selectedMicrophone = microphoneId;


        if (!cameraId || !microphoneId) {
            const devices = await AgoraRTC.getDevices();

            const audioDevices = devices.filter((device) => {
                return device.kind === "audioinput";
            });

            selectedMicrophone = audioDevices[0].deviceId;

            const videoDevices = devices.filter((device) => {
                return device.kind === "videoinput";
            });

            selectedCamera = videoDevices[0].deviceId;

            const preferredCamera = localStorage.getItem('preferred-camera');
            const preferredMicrophone = localStorage.getItem('preferred-microphone');

            if(preferredCamera && videoDevices.find((device) => device.deviceId === preferredCamera)){
                selectedCamera = preferredCamera;
            }

            if(preferredMicrophone && audioDevices.find((device) => device.deviceId === preferredMicrophone)){
                selectedMicrophone = preferredMicrophone;
            }
        }

        this.localMicrophone = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: selectedMicrophone
        });

        this.localVideo = await AgoraRTC.createCameraVideoTrack({
            cameraId: selectedCamera
        });

        // Vanity Video
        const video = document.createElement("video");
        video.id = "local-video-view";
        video.style.position = "absolute";
        video.style.top = "20px";
        // video.style.left = "20px";
        video.style.right = "20px";
        video.style.width = "160px";
        video.style.height = "90px";
        video.srcObject = new MediaStream([
            this.localVideo.getMediaStreamTrack(),
        ]);
        await video.play();

        this.localVideoView = video;

        const root = document.getElementById("root");
        root?.appendChild(video);
        this.localMicrophone.setMuted(false)

    }

    update = async () => {
        const pos = this.scene.controls.position;
        this.currentCell = this.getCell(pos.x, pos.z);
        if(JSON.stringify(this.prevCell) !== JSON.stringify(this.currentCell)){
            this.prevCell[0] = this.currentCell[0];
            this.prevCell[1] = this.currentCell[1];
            this.updateDebugCells();
            await this.updateConnections();
        }
    }

    
    getCell = (x: number, y: number) => {
        const row = Math.floor(x / this.cellSize);
        const col = Math.floor(y / this.cellSize);
        return [row, col] as Cell;
    }

    getSurroundingCells = (cell: Cell) => {
        const up: Cell = [cell[0], cell[1] + 1]
        const down: Cell = [cell[0], cell[1] - 1]
        const left: Cell = [cell[0] - 1, cell[1]]
        const right: Cell = [cell[0] + 1, cell[1]]

        const topLeft: Cell = [up[0] - 1, up[1]]
        const topRight: Cell= [up[0] + 1, up[1]]
        const bottomLeft: Cell = [down[0] - 1, down[1]]
        const bottomRight: Cell = [down[0] + 1, down[1]]

        return {
            up,
            down,
            left,
            right,
            topLeft,
            topRight,
            bottomLeft,
            bottomRight
        }
    }

    getCellCenter = (cell: Cell) => {
        return new THREE.Vector2((cell[0] * this.cellSize) + this.cellSize / 2, (cell[1] * this.cellSize) + this.cellSize / 2)
    }

    generateDebugCells = () => {
        const geom = new THREE.PlaneGeometry(this.cellSize - 0.1, this.cellSize- 0.1);
        for(let i = 0; i < 9; i++){
            const m = new THREE.Mesh(geom, this.currentCellMat);
            m.rotateX(-Math.PI / 2)
            this.scene.scene.add(m);
            this.objects.push(m);
        }
    }

    updateDebugCells = () => {

        const updateCell = (index: number, cell: Cell, primary?: boolean) => {
            const c0Pos = this.getCellCenter(cell);
            this.objects[index].position.setX(c0Pos.x);
            this.objects[index].position.setZ(c0Pos.y);
            if(primary){
                this.objects[index].material = this.currentCellMat;
            }else{
                this.objects[index].material = this.surroundingCellMat;
            }
        }

        updateCell(0, this.currentCell, true);

        const surrounding = this.getSurroundingCells(this.currentCell);
        updateCell(1, surrounding.up);
        updateCell(2, surrounding.down);
        updateCell(3, surrounding.left);
        updateCell(4, surrounding.right);

        updateCell(5, surrounding.topLeft);
        updateCell(6, surrounding.topRight);
        updateCell(7, surrounding.bottomLeft);
        updateCell(8, surrounding.bottomRight);
    }

    updateConnections = async () => {

        // const surrounding = this.getSurroundingCells(this.currentCell);
        //
        // const cells: Cell[] = [
        //     this.currentCell,
        //     surrounding.up,
        //     surrounding.down,
        //     surrounding.left,
        //     surrounding.right,
        //     surrounding.topLeft,
        //     surrounding.topRight,
        //     surrounding.bottomLeft,
        //     surrounding.bottomRight
        // ]
        //
        // for(const [channelId, client] of this.connections.entries()){
        //     if(!cells.find((c) => this.getCellChannelId(c) === channelId)){
        //         // Remove existing subscriptions here.
        //         for(const user of this.users.values()){
        //             this.removeUserSubscriptions(client, user);
        //         }
        //
        //         console.log('Disconnecting: ' + channelId)
        //         await client.leave();
        //
        //         this.connections.delete(channelId);
        //     }
        // }
        //
        // for(const cell of cells){
        //     if(!this.connections.get(this.getCellChannelId(cell))){
        //         this.createRtcClient(cell)
        //     }
        // }

        // Single channel connect/disconnect
        for(const [channelId, client] of this.connections.entries()){
            // Remove existing subscriptions here.
            for(const user of this.users.values()){
                this.removeUserSubscriptions(client, user);
            }

            console.log('Disconnecting: ' + channelId)
            await client.leave();

            this.connections.delete(channelId);
        }

        await this.createRtcClient(this.currentCell)
    }

    createRtcClient = async (cell: Cell) => {
        const client = AgoraRTC.createClient({
            mode: "live",
            codec: "vp8",
            role: "host",
        });

        this.setupRtcClientEvents(client);

        const channelId = this.getCellChannelId(cell);
        console.log('Connecting: ' + channelId)

        this.connections.set(channelId, client);

        await client.join(appId, channelId , null, this.uid);
        if(this.localMicrophone && this.localVideo){
            await client.publish([this.localMicrophone, this.localVideo]);
        }
        console.log("publish success");
    }

    getCellChannelId = (cell: Cell) => {
        return `${this.meetingId}-channel-[${cell[0]}:${cell[1]}]`
    }

    handleRtmEvents = () => {

        if(!this.rtm_channel) throw new Error('RTM Channel not initialised.')

        this.rtm_channel.on('MemberLeft', async (sender_id) => {
            await queue.push(async () => {
                const user = await this.getOrCreateUser(sender_id);
                this.scene.scene.remove(user.object);
                this.users.delete(user.uid);
                console.log('Removed user from scene')
            });
        })

        // event listener for receiving a channel message
        this.rtm_channel.on("ChannelMessage", async (event, sender_id) => {

            await queue.push(async () => {
                const user = await this.getOrCreateUser(sender_id);

                switch (event.messageType){
                    case "TEXT": {
                        // convert from string to JSON
                        const msg = JSON.parse(event.text) as Message;

                        switch (msg.type) {
                            case "POSITION": {
                                const { position, instant } = msg.data;
                                await this.moveModel(sender_id, position, instant);
                                break;
                            }
                            case "ROTATION": {
                                await this.rotateModel(
                                    sender_id,
                                    this.unpackQuaternion(msg.data)
                                );
                                break;
                            }
                            case "CUSTOM": {
                                const data = JSON.parse(msg.data);
                                const handler = this.customEventHandlerMap.get(data.id);
                                handler[data.functionName](data.functionArgs);
                            }
                        }
                        break;
                    }
                }
            })


        });
    };

    private getOrCreateUser = async (uid: string) =>{
        let user: RemoteUser | undefined = this.users.get(uid);

        if(!user){
            user = {
                initialised: false,
                object: await this.createUserModel(uid),
                targetPosition: undefined,
                targetRotation: undefined,
                uid
            }
            this.users.set(uid, user);
        }

        return user;
    }

    moveSelf = async (position: THREE.Vector3, instant?: boolean) => {
        await this.moveModel(this.uid, position, instant, true);
    };

    moveModel = async (uid: string, position: THREE.Vector3, instant?: boolean, send?: boolean) => {
        const timeNow = new Date().getTime();

        if (send) {
            if (this.lastUpdateTime + 150 < timeNow) {
                this.lastUpdateTime = timeNow;
                await this.sendChannelMessage("POSITION", {
                    position,
                    instant,
                });
            }
        } else {
            const user = this.users.get(uid);
            if (user) {
                if (instant) {
                    user.object.position.set(position.x, position.y, position.z);
                } else {
                    user.targetPosition = position;
                }

                if (!user.initialised) {
                    user.object.visible = true;
                    user.initialised = true;
                }
            } else {
                console.log("Failed to find matching model for UID");
            }
        }
    };

    packQuaternion = (q: THREE.Quaternion) => {
        return JSON.stringify({
            x: q.x,
            y: q.y,
            z: q.z,
            w: q.w,
        });
    };

    unpackQuaternion = (s: string) => {
        let o = JSON.parse(s);
        let q = new THREE.Quaternion(o.x, o.y, o.z, o.w);
        const baseRotation = new THREE.Quaternion();
        baseRotation.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI);
        q.multiply(baseRotation);
        return q;
    };

    rotateSelf = async (q: THREE.Quaternion, force?: boolean) => {
        await this.rotateModel(this.uid, q, true, force);
        //NOTE TO GEORGE rotating self - this calls rotateModel
    };

    rotateModel = async (uid: string, q: THREE.Quaternion, send?: boolean, force?: boolean) => {
        const timeNow = new Date().getTime();

        if (send) {
            if (force || this.lastUpdateTime + 150 < timeNow) {
                this.lastUpdateTime = timeNow;

                //NOTE TO GEORGE called by rotateSelf - we send the current viewpoint rotation (just Y for now) via Agora RTM from LookcontrolsV2.js
                await this.sendChannelMessage(
                    "ROTATION",
                    this.packQuaternion(q)
                );
            }
        } else {
            const user = this.users.get(uid);
            if (user) {
                user.object.quaternion.copy(q);
            }
        }
    };

    updatePositions = () => {
        this.users.forEach((user) => {

            const posTarget = user.targetPosition;

            if (posTarget) {
                if (user.object.position.distanceTo(posTarget) > 0.1) {
                    user.object.position.lerp(posTarget, 0.03);
                } else {
                    user.targetPosition = undefined;
                }
            }
        });

        window.requestAnimationFrame(this.updatePositions)
    };

    sendChannelMessage = async (type: MessageType, data: any) => {
        if (this.rtm_channel && this.rtm_joined) {
            const dataMessage: Message = {
                type,
                data,
            }

            // Build the Agora RTM Message
            const msg: RtmMessage = {
                description: undefined,
                messageType: "TEXT",
                rawMessage: undefined,
                text: JSON.stringify(dataMessage),
            };

            try {
                await this.rtm_channel.sendMessage(msg);
            } catch (err) {
                console.log("Error sending channel message.");
                console.error(err);
            }
        }
    };

    private async createUserModel (uid: string){
        const loader = new GLTFLoader();
        const gltf = await loader.loadAsync("assets/models/client.glb");
        const model = gltf.scene;
        model.name = uid;
        model.scale.setScalar(0.7);
        model.userData.init = false;
        model.visible = false;

        const video = document.createElement("video");
        video.poster = "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.ytimg.com%2Fvi%2F29S4LuG14Us%2Fmaxresdefault.jpg&f=1&nofb=1"
        video.style.display = "none";
        video.id = "face-video-" + uid;
        video.setAttribute("webkit-playsinline", "webkit-playsinline");
        video.setAttribute("playsinline", "playsinline");
        video.muted = true;

        const root = document.getElementById("root");
        root!.appendChild(video);

        const texture = new THREE.VideoTexture(video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.flipY = false;

        model.traverse((node: THREE.Mesh | any) => {
            // search the mesh's children for the face-geo
            if (node.isMesh && node.name === "face-geo") {
                node.material.map = texture;
            }
        });

        this.scene.scene.add(model);
        return model;
    }

    registerCustomEventHandler = (id: string, object: any) => {
        this.customEventHandlerMap.set(id, object);
    };

    private setupRtcClientEvents(client: IAgoraRTCClient){
        client.on("user-published", async (remoteUser, mediaType) => {

            await queue.push(async () => {
                const user = await this.getOrCreateUser(remoteUser.uid.toString());

                console.log("User published: " + mediaType);
                console.log(user);

                if (mediaType === "video" && !user.rtcVideoSubscribedChannel) {
                    await client.subscribe(remoteUser, mediaType);
                    console.log("subscribe video success");
                    user.rtcVideoSubscribedChannel = client.channelName;

                    const video = document.getElementById(
                        "face-video-" + user.uid
                    ) as HTMLVideoElement;

                    if(!video) throw new Error('No video element for user: ' + remoteUser.uid);
                    video.srcObject = new MediaStream([
                        // @ts-ignore
                        remoteUser.videoTrack.getMediaStreamTrack(),
                    ]);
                    await video.play();
                }

                if (mediaType === "audio" && !user.rtcAudioSubscribedChannel) {

                    await client.subscribe(remoteUser, mediaType);
                    console.log("subscribe audio success");
                    user.rtcAudioSubscribedChannel = client.channelName;

                    if(this.options.positionalAudioEnabled){

                        console.info("Positional audio enabled!")

                        const model = this.scene.scene.getObjectByName(remoteUser.uid);

                        const stream = new MediaStream([
                            // @ts-ignore
                            remoteUser.audioTrack.getMediaStreamTrack(),
                        ]);
                        //
                        // // https://threejs.org/docs/index.html?q=positi#api/en/audio/PositionalAudio.panner
                        // // https://developer.mozilla.org/en-US/docs/Web/API/PannerNode

                        const positionalAudio = new THREE.PositionalAudio(
                            this.scene.listener
                        );
                        positionalAudio.play();
                        positionalAudio.setMediaStreamSource(stream);
                        positionalAudio.setRefDistance(4);
                        positionalAudio.setMaxDistance(5);
                        positionalAudio.setRolloffFactor(5);
                        positionalAudio.setDirectionalCone( 180, 230, 0.1 );
                        model.add(positionalAudio);

                        if(this.options.positionalAudioHelperEnabled) {
                            const helper = new PositionalAudioHelper(positionalAudio, 2);
                            helper.layers.disableAll();
                            helper.layers.enable(RAYCAST_EXCLUDE_LAYER);
                            positionalAudio.add(helper);
                        }
                    }else {
                        // @ts-ignore
                        remoteUser.audioTrack.play();
                    }
                }
            })
        });

        // User Leave RTC Channel
        client.on("user-left", async (remoteUser, reason) => {
            const user = await this.getOrCreateUser(remoteUser.uid.toString());
            this.removeUserSubscriptions(client, user);
        });
    }

    private removeUserSubscriptions(client: IAgoraRTCClient, user: RemoteUser){
        if(user.rtcVideoSubscribedChannel === client.channelName){
            console.log('Unsubscribed from remote user RTC video. ')
            user.rtcVideoSubscribedChannel = undefined
        }

        if(user.rtcAudioSubscribedChannel === client.channelName){
            console.log('Unsubscribed from remote user RTC audio. ')
            user.rtcAudioSubscribedChannel = undefined
        }
    }

    currentCamera = () => {
        return this.localVideo?.getMediaStreamTrack().getSettings().deviceId;
    };

    currentMicrophone = () => {
        return this.localMicrophone?.getMediaStreamTrack().getSettings().deviceId;
    };

    setCamera = async (device_id: string) => {
        localStorage.setItem('preferred-camera', device_id);
        if(this.localVideo){
            await this.localVideo.setDevice(device_id);
            if(this.localVideoView){
                this.localVideoView.srcObject = new MediaStream([
                    this.localVideo.getMediaStreamTrack(),
                ]);
                await this.localVideoView?.play();
            }

        }
    };

    getPreferredCamera = () => {
        return localStorage.getItem('preferred-camera');
    }

    getPreferredMicrophone = () => {
        return localStorage.getItem('preferred-microphone');
    }

    setMicrophone = async (device_id: string) => {
        localStorage.setItem('preferred-microphone', device_id);
        if(this.localMicrophone){
            await this.localMicrophone.setDevice(device_id);
        }
    };

    muteAudio = () => {
        if(this.localMicrophone){
            this.localMicrophone.setMuted(true)
            this.audio_muted = true;
        }
    };

    muteVideo = () => {
        if(this.localVideo){
            this.localVideo.setMuted(true)
            if(this.localVideoView){
                this.localVideoView.style.display = "none"
            }
            this.video_muted = true;
        }
    };

    unmuteAudio = () => {
        if(this.localMicrophone){
            this.localMicrophone.setMuted(false)
            this.audio_muted = false;
        }
    };

    unmuteVideo = async () => {
        if(this.localVideo){
            await this.localVideo.setMuted(false)
            this.video_muted = false;
            if(this.localVideoView){
                this.localVideoView.style.display = "block"
                this.localVideoView.srcObject = new MediaStream([
                    this.localVideo.getMediaStreamTrack(),
                ]);
                await this.localVideoView.play();
            }
        }
    };
}