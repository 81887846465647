//todo - various updates need copying over

import * as THREE from "three";
import Multiplayer from "../three-components/Multiplayer2";
import LookControlsV2 from "../three-components/LookControlsV2";
import { makeAutoObservable } from "mobx";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Component from "../three-components/Component";
import HDREnvironment from "../three-components/HDREnvironment";
import Heightmap from "../three-components/heightmap";
import MovePad from "../three-components/MovePad";
import Clickable from "../three-components/Clickable";
import Draggable from "../three-components/Draggable";
import Hoverable from "../three-components/Hoverable";
import Whiteboard from "../three-components/Whiteboard3";
import WhiteboardControls from "../three-components/WhiteboardControls";
import Screenshot from "../three-components/Screenshot";

import classic from "../rooms/classic.json";
import brutalist from "../rooms/brutalist.json";
import artgallery from "../rooms/artgallery.json";
import exhibitionSpace from "../rooms/exhibitionSpace.json";
import moodyArtGallery from "../rooms/moodyArtGallery.json";
import conferenceRoom from "../rooms/conferenceRoom.json";
import natureLounge from "../rooms/natureLounge.json";
import festiverse from "../rooms/festiverse.json";
import syngentaPitchRoom from "../rooms/SyngentaPitchRoom.json";
import littlestTokyo from "../rooms/littlestTokyo.json";
import drawingRoom from "../rooms/drawingRoom.json";
import liquidGalleryPhase01draft_V01 from "../rooms/liquidGalleryPhase01draft_V01.json";
import liquidGalleryPhase01draft_V02 from "../rooms/liquidGalleryPhase01draft_V02.json";
import liquidGalleryPhase01draft_V03 from "../rooms/liquidGalleryPhase01draft_V03.json";
import liquidGalleryPhase01draft_V04 from "../rooms/liquidGalleryPhase01draft_V04.json";
// import testRoom from "../rooms/test.json";

import ImageViewer from "../three-components/ImageViewer";
import ModalTriggerPDF from "../three-components/modal-triggerPDF";
import VideoViewer from "../three-components/VideoViewer";
import VideoViewerGreenscreen from "../three-components/VideoViewerGreenscreen";
// import React from "react";
import DraggableObject from "../three-components/DraggableObject";
import ScreenObject from "../three-components/Screen";
import ObjectRotator from "../three-components/ObjectRotator";
import App from "./App";
import AvatarBuilder from "../three-components/AvatarBuilder";
import ImageViewerNFT from "../three-components/ImageViewerNFT";
import ImageViewerShop from "../three-components/ImageViewerShop";
import LiveStreamScreen from "../three-components/LiveStreamScreen";
import LiveStreamClient from "../three-components/LiveStreamClient";
import MultiplayerClient from "../three-components/MultiplayerClient";
export const RAYCAST_EXCLUDE_LAYER = 31;
export const LIVE_STREAM_SCREEN_ID = "live-stream-screen"

export const ROOMS = [classic, brutalist, artgallery, exhibitionSpace, moodyArtGallery, conferenceRoom, natureLounge, festiverse, syngentaPitchRoom, littlestTokyo, drawingRoom, liquidGalleryPhase01draft_V01, liquidGalleryPhase01draft_V02, liquidGalleryPhase01draft_V03, liquidGalleryPhase01draft_V04 ];

export function GetRoomById(id) {
    return ROOMS.find((room) => room.id === id);
}

export default class Scene {

    loading = true;
    hosting = false;

    dragging = false;
    mouse = new THREE.Vector2();
    activeWhiteboard = undefined;
    activeWhiteboardTextOpen = false;

    constructor(mount) {
        makeAutoObservable(this);

        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            powerPreference: "high-performance",
        });
        //todo - sanity check lighting
        // https://www.donmccurdy.com/2020/06/17/color-management-in-threejs/

        //standard setup used for three.js example damaged helmet
        // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        // this.renderer.toneMappingExposure = 1.0;
        // this.renderer.outputEncoding = THREE.sRGBEncoding;

        this.renderer.toneMapping = THREE.LinearToneMapping;
        // this.renderer.toneMappingExposure = 1.0;
        // this.renderer.outputEncoding = THREE.sRGBEncoding;

        this.renderer.toneMappingExposure = 1.25;
        this.renderer.outputEncoding = THREE.sRGBEncoding;

        //not usable here:
        // this.texture.mapping = THREE.EquirectangularReflectionMapping;
        // this.scene.background = texture;
        // this.scene.environment = texture;

        //todo -
        // https://github.com/mrdoob/three.js/blob/master/examples/webgl_loader_gltf.html

        this.renderer.setSize(window.innerWidth, window.innerHeight);
        mount.appendChild(this.renderer.domElement);


        // Setup Loading Manager
        this.loadingManager = new THREE.LoadingManager();

        this.loadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
            console.log("Started loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files.");
            this.loading = true;
        };

        this.loadingManager.onLoad = () => {
            console.log("Loading complete!");
            this.loading = false;
        };

        this.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
            console.log("Loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files.");
        };

        this.loadingManager.onError = (url) => {
            console.log("There was an error loading " + url);
            this.loadingError = true;
        };

        // Setup Multiplayer
        this.multiplayer = new Multiplayer(this.scene, {
            engine: this,
            autoLeave: false,

            onJoin: () => {

                // alert("on join")

                // Very simple and dirty max user cap
                // if(this.roomData && this.roomData.maxUsers){
                //     console.log(this.roomData.maxUsers)
                //     if (this.multiplayer.rtc_client.remoteUsers.length >= this.roomData.maxUsers) {
                //         this.multiplayer.leave();
                //         throw new Error("Max User Limit")
                //     }
                // }

                if (this.multiplayer.rtc_client.remoteUsers.length === 0) {
                    this.hosting = true;
                    this.controls.moveForward(5);
                    this.controls.setRotationFromEuler(
                        new THREE.Euler(
                            THREE.MathUtils.degToRad(0),
                            THREE.MathUtils.degToRad(180),
                            THREE.MathUtils.degToRad(0)
                        )
                    );
                }
                this.controls.forceSendTransformUpdate();
            },
            onRemoteUserJoin: () => {
                this.controls.forceSendTransformUpdate();
            },
            onLeave: () => {
                // setJoinOpen(true)
            },
        });

        this.multiplayer.registerCustomEventHandler("scene", this)

        // New Multiplayer Client
        this.multiplayerClient = new MultiplayerClient(this);
        this.multiplayerClient.join('6577325732648').then(() => {
            // this.multiplayerClient.muteAudio();
        });

        // Live Stream Client
        this.liveStreamClient = new LiveStreamClient(LIVE_STREAM_SCREEN_ID)

        // Setup Camera and Controls
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 200);
        this.camera.layers.enableAll();

        this.controls = new LookControlsV2(this.camera, this.renderer, this.scene, this.multiplayerClient);
        this.controls.enabled = true;
        //todo - make start position dynamic



        // simple spawn position / start position randomiser
        const min = -5;
        const max = 5;
        const randx = min + Math.random() * (max - min);
        const randz = min + Math.random() * (max - min);

        this.controls.moveTo(new THREE.Vector3( randx ,1.6,  randz));
        this.controls.postMove = (pos, rot) => {
            if (this.heightmap) {
                this.heightmap.onMove(pos, rot);
            }
        };

        // Setup Extras


        this.screenshot = new Screenshot(this.scene, this.camera, this.renderer);
        this.clock = new THREE.Clock();
        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.disable(RAYCAST_EXCLUDE_LAYER);

        this.listener = new THREE.AudioListener();
        this.camera.add(this.listener);

        const onWindowResize = () => {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener("resize", onWindowResize, false);

        this.setupLogic();
        this.animate();
    }

    animate = () => {
        const delta = this.clock.getDelta();

        // Update Multiplayer
        this.multiplayer.update();

        // Update components
        Component.components.forEach((component) => component.update(delta));

        // Update Heightmap
        if (this.heightmap) {
            const cameraHeight = this.heightmap.update(this.renderer);
            this.controls.setCameraHeight(cameraHeight);
        }

        // render 3D scene
        this.renderer.render(this.scene, this.camera);

        requestAnimationFrame(this.animate);
    };

    async switchScene(id, send){
        if(send){
            await this.multiplayer.sendCustomChannelMessage("scene", "switchScene", id)
        }

        window.setTimeout(() => {
            const key = encodeURIComponent('sceneOverride');
            const value = encodeURIComponent(id);

            // kvp looks like ['key1=value1', 'key2=value2', ...]
            const kvp = document.location.search.substr(1).split("&");
            let i=0;

            for(; i<kvp.length; i++){
                if (kvp[i].startsWith(key + '=')) {
                    let pair = kvp[i].split('=');
                    pair[1] = value;
                    kvp[i] = pair.join('=');
                    break;
                }
            }

            if(i >= kvp.length){
                kvp[kvp.length] = [key,value].join('=');
            }

            // can return this or...
            let params = kvp.join('&');

            // reload page with new params
            document.location.search = params;
        }, 2000)
    }

    loadRoomById = (id) => {
        const room = GetRoomById(id);
        if (!room) throw new Error("No room configured with ID: " + id);
        this.loadScene(room);
    }

    loadScene = (config) => {
        const loader = new GLTFLoader(this.loadingManager);

        this.roomData = config;

        if(config.theme){
            App.setAppTheme(config.theme);
        }

        this.multiplayer.updateOptions({
            positionalAudioEnabled: config?.positionalAudioEnabled || false,
            positionalAudioHelperEnabled: config?.positionalAudioHelperEnabled || false,
        })

        if (config.rooms) {
            config.rooms.forEach((def) => {
                loader.load(def.url, (gltf) => {
                    const room = gltf.scene;
                    room.name = "room";
                    room.visible = true;
                    room.castShadow = true;
                    room.receiveShadow = true;
                    this.scene.add(room);

                    if (def.ignoreRaycast) {
                        room.traverse((x) => {
                            x.layers.disableAll();
                            x.layers.enable(RAYCAST_EXCLUDE_LAYER);
                        });
                    }

                    if (def.position) {
                        room.position.fromArray(def.position);
                    }
                    if (def.scale) {
                        room.scale.fromArray(def.scale);
                    }
                });
            });
        }

        if (config.hdr) {
            //todo - sanity check lighting
            new HDREnvironment(this.scene, this.renderer, config.hdr.url);
 
            // const ambientLight = new THREE.AmbientLight(0xFFFFFF);
            // const ambientLightIntensity = 0.001;
            // this.scene.add(ambientLight,ambientLightIntensity);

            // const directionalLight = new THREE.AmbientLight(0xFFFFFF);
            // const directionalLightIntensity = 1.0;
            // this.scene.add(directionalLight, directionalLightIntensity);

            // const hemisphereLight = new THREE.HemisphereLight(0xFFFFFF);
            // const hemisphereLightSkyColor = 0x404040;
            // const hemisphereLightGroundColor = 0xFFFFFF;
            // const hemisphereLightIntensity = 1.0;
            // this.scene.add(hemisphereLight, hemisphereLightSkyColor, hemisphereLightGroundColor, hemisphereLightIntensity);

            // const pointLight = new THREE.PointLight(0xFFFFFF);
            // const pointLightIntensity = 1.0;
            // const pointLightDistance = 0;
            // this.scene.add(pointLight, pointLightIntensity, pointLightDistance );

            // const spotLight = new THREE.SpotLight(0xFFFFFF);
            // const spotLightIntensity = 1.0;
            // const spotLightDistance = 0;
            // const spotLightAngle = Math.PI/2;
            // this.scene.add(spotLight, spotLightIntensity, spotLightDistance, spotLightAngle);


        }

        if (config.heightmap) {
            this.heightmap = new Heightmap(this.scene, config.heightmap.url, this.camera);
        }

        if (config.pads) {
            config.pads.forEach((def) => {

                if(def.padSrc)
                {
                    loader.load(def.padSrc, (gltf) =>
                    {
                        const padProp = gltf.scene;

                        if (def.padPropPosition)
                        {
                            padProp.position.fromArray(def.padPropPosition);
                        }
                        else
                        {
                            padProp.position.fromArray(def.position);
                        }


                        if (def.padPropScale)
                        {
                            padProp.scale.fromArray(def.padPropScale);
                        }

                        if (def.padPropRotation)
                        {
                            const rotVec = new THREE.Vector3().fromArray(def.padPropRotation);
                            rotVec.multiplyScalar(THREE.MathUtils.DEG2RAD);
                            const rotation = new THREE.Euler().setFromVector3(rotVec);
                            padProp.rotation.copy(rotation);
                            padProp.updateMatrixWorld(true);
                        }

                        if (def.padPropRotateSpeed)
                        {
                            new ObjectRotator(padProp, def.padPropRotateSpeed);
                        }
                        else
                        {
                            new ObjectRotator(padProp, 0.2);
                        }

                        this.scene.add(padProp);
                        padProp.traverse((obj) => {
                            obj.layers.disableAll();
                            obj.layers.enable(RAYCAST_EXCLUDE_LAYER);
                        });
                    });
                }


                const pad = new MovePad(def.hero, this.scene, this.controls);
                pad.position = new THREE.Vector3().fromArray(def.position);
                // pad.scale = new THREE.Vector3().fromArray(def.scale);
                pad.rotateTo = new THREE.Quaternion().fromArray(def.rotateTo);
                pad.rotateToViewEast = new THREE.Quaternion().fromArray(def.rotateToViewEast);
                pad.rotateToViewSouth = new THREE.Quaternion().fromArray(def.rotateToViewSouth);
                pad.rotateToViewWest = new THREE.Quaternion().fromArray(def.rotateToViewWest);
                if (def.offsetPosition) pad.offsetPosition = new THREE.Vector3().fromArray(def.offsetPosition);
                if (App.isMobile){
                    pad.clickableAreaScale = 2;
                }
            });
        }

        if (config.whiteboards) {
            // todo - fix Whiteboards - disabled on mobile because they seem to cause issues

            if (!App.isMobile){
                config.whiteboards.forEach((def) => {
                    const whiteboard = new Whiteboard({
                        uuid: def.uuid,
                        scene: this.scene,
                        images: ["assets/textures/whiteboard0.jpg"],
                        camera: this.camera,
                        renderer: this.renderer,
                        width: def.width,
                        height: def.height,
                        onDrawStart: () => (this.controls.enabled = false),
                        onDrawStop: () => (this.controls.enabled = true),
                        onEnterTextMode: () => {
                            this.activeWhiteboard = whiteboard;
                            this.activeWhiteboardTextOpen = true;
                            this.controls.enabled = false;
                        },
                        onLeaveTextMode: () => {
                            this.activeWhiteboard = undefined;
                            this.activeWhiteboardTextOpen = false;
                            this.controls.enabled = true;
                        },
                    });

                    if (def.position) whiteboard.setPosition(def.position[0], def.position[1], def.position[2]);
                    if (def.rotation) whiteboard.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);

                    new WhiteboardControls(whiteboard, this.scene, def.buttonSize);

                    // if(def.postits){
                    //     def.postits.forEach((def1) => {
                    //         const postIt = new PostItNote(
                    //             def1.uuid,,
                    //             this.scene,
                    //             7.0,
                    //             0.8,
                    //             12.5,
                    //             0.0,
                    //             "assets/icons/whiteboard-icons-01.jpg",
                    //             "plane_gallery",
                    //             -180
                    //         );
                    //     })
                    // }
                });
            }

        }

        if (config.slideshows) {
            config.slideshows.forEach((def) => {
                const imageViewer = new ImageViewer(
                    this.renderer,
                    def.uuid,
                    this.scene,
                    def.images,
                    def.width,
                    def.height,
                    def.offsetControlPosition

                );

                if (def.position) imageViewer.setPosition(def.position[0], def.position[1], def.position[2]);
                if (def.rotation) imageViewer.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
            });
        }

        if (config.avatarBuilder) {
            config.avatarBuilder.forEach((def) => {
                const avatarBuilder = new AvatarBuilder(
                    this.renderer,
                    def.uuid,
                    this.scene,
                    def.items,
                    def.width,
                    def.height,
                    def.controlsOffset
                );

                if (def.position) avatarBuilder.setPosition(def.position[0], def.position[1], def.position[2]);
                if (def.rotation) avatarBuilder.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
            });
        }

        if (config.imageViewerNFT) {
            config.imageViewerNFT.forEach((def) => {
                const imageViewerNFT = new ImageViewerNFT(
                    this.renderer,
                    def.uuid,
                    this.scene,
                    def.items,
                    def.width,
                    def.height
                );

                if (def.position) imageViewerNFT.setPosition(def.position[0], def.position[1], def.position[2]);

                if (def.rotation) imageViewerNFT.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
            });
        }

        if (config.imageViewerShop) {
            config.imageViewerShop.forEach((def) => {
                const imageViewerShop = new ImageViewerShop(
                    this.renderer,
                    def.uuid,
                    this.scene,
                    def.items,
                    def.width,
                    def.height
                );

                if (def.position) imageViewerShop.setPosition(def.position[0], def.position[1], def.position[2]);

                if (def.rotation) imageViewerShop.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
            });
        }

        if (config.pdfs) {
            config.pdfs.forEach((def) => {

                if(def.src)
                {
                    loader.load(def.src, (gltf) =>
                    {
                        const pdfProp = gltf.scene;

                        if (def.propPosition)
                        {
                            pdfProp.position.fromArray(def.propPosition);
                        }
                        if (def.propScale)
                        {
                            pdfProp.scale.fromArray(def.propScale);
                        }

                        if (def.propRotation)
                        {
                            const rotVec = new THREE.Vector3().fromArray(def.propRotation);
                            rotVec.multiplyScalar(THREE.MathUtils.DEG2RAD);
                            const rotation = new THREE.Euler().setFromVector3(rotVec);
                            pdfProp.rotation.copy(rotation);
                            pdfProp.updateMatrixWorld(true);
                        }

                        if (def.propRotateSpeed)
                        {
                            new ObjectRotator(pdfProp, def.propRotateSpeed);
                        }

                        this.scene.add(pdfProp);
                        pdfProp.traverse((obj) => {
                            obj.layers.disableAll();
                            obj.layers.enable(RAYCAST_EXCLUDE_LAYER);
                        });
                    });
                }



                const trigger = new ModalTriggerPDF(this.scene, () => window.open(def.pdf, "_blank"), def.imagePDF);
                trigger.setPositionFromArray(def.position);
                trigger.setRotation(def.rotation);
                trigger.setScale(def.scale);
            });
        }




        if (config.videos) {
            config.videos.forEach((def) => {
                const root = document.getElementById("root");

                const element = document.createElement("video");
                element.id = def.uuid;
                element.style.display = "none";
                element.playsInline = true;
                element.crossOrigin = "anomynous";
                element.loop = true;
                element.src = def.src;

                root.appendChild(element);

                const videoViewer = new VideoViewer(
                    def.uuid,
                    this.scene,
                    this.listener,
                    def.poster,
                    def.width,
                    def.height
                );
                if (def.position) videoViewer.setPosition(def.position[0], def.position[1], def.position[2]);

                if (def.rotation) videoViewer.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
            });
        }

        if (config.videosGreenscreen) {
            config.videosGreenscreen.forEach((def) => {
                const root = document.getElementById("root");

                const element = document.createElement("video");
                element.id = def.uuid;
                element.style.display = "none";
                element.playsInline = true;
                element.crossOrigin = "anomynous";
                element.loop = true;
                element.src = def.src;

                root.appendChild(element);

                const videoViewerGreenscreen = new VideoViewerGreenscreen(
                    def.uuid,
                    this.scene,
                    this.listener,
                    def.poster,
                    def.width,
                    def.height
                );
                if (def.position) videoViewerGreenscreen.setPosition(def.position[0], def.position[1], def.position[2]);
                if (def.rotation) videoViewerGreenscreen.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                // if (def.visibility) videoViewerGreenscreen.setPosition(def.position[0], def.position[1], def.position[2]);

            });
        }

        if (config.draggables) {
            config.draggables.forEach((def) => {
                //
                loader.load(def.src, (gltf) => {
                    const geometry = new THREE.BoxGeometry(1.25, 1.25, 1.25);
                    const sphere = new THREE.Mesh(
                        geometry,
                        new THREE.MeshBasicMaterial({
                            opacity: 0.0,
                            transparent: true,
                        })
                    );

                    const draggableSculpture = gltf.scene;
                    draggableSculpture.scale.setScalar(1.0);
                    draggableSculpture.rotateY(-Math.PI / 0.8);
                    draggableSculpture.traverse((obj) => {
                        obj.layers.disableAll();
                        obj.layers.enable(RAYCAST_EXCLUDE_LAYER);
                    });

                    sphere.add(draggableSculpture);
                    sphere.position.fromArray(def.position);
                    if(def.rotation) sphere.rotation.copy(convertFromEulerArray(def.rotation));
                    if(def.scale) sphere.scale.fromArray(def.scale);
                    this.scene.add(sphere);

                    new DraggableObject(def.uuid, sphere, this.camera);
                });
            });
        }

        if (config.props) {
            config.props.forEach((def) => {
                //
                loader.load(def.src, (gltf) => {

                    const prop = gltf.scene;

                    if(def.position){
                        prop.position.fromArray(def.position);
                    }
                    if(def.scale){
                        prop.scale.fromArray(def.scale);
                    }

                    if(def.rotation){
                        const rotVec = new THREE.Vector3().fromArray(def.rotation);
                        rotVec.multiplyScalar(THREE.MathUtils.DEG2RAD);
                        // const rotation = new THREE.Euler((def.rotation[0] || 0) * THREE.MathUtils.DEG2RAD, (def.rotation[1] || 0) * THREE.MathUtils.DEG2RAD, (def.rotation[2] || 0) * THREE.MathUtils.DEG2RAD);
                        const rotation = new THREE.Euler().setFromVector3(rotVec);
                        prop.rotation.copy(rotation);
                        prop.updateMatrixWorld(true);
                    }

                    if(def.rotateSpeed){
                        new ObjectRotator(prop, def.rotateSpeed);
                    }

                    this.scene.add(prop);
                });
            });
        }

        if (config.screenshare) {
            const def = config.screenshare;

            const screen = new ScreenObject("screen-share", this.scene, def.width, def.height);
            screen.board.position.fromArray(def.position);
            screen.board.rotation.y = THREE.MathUtils.degToRad(def.rotY);

            screen.board.visible = false;

            this.multiplayer.onRemoteUserStartScreenShare.on(() => {
                screen.board.visible = true;
            });

            this.multiplayer.onRemoteUserStopScreenShare.on(() => {
                screen.board.visible = false;
            });
        }

        if (config.liveStreamScreen) {
            const def = config.liveStreamScreen;

            const liveStreamScreen = new LiveStreamScreen(LIVE_STREAM_SCREEN_ID, this.scene, this.listener);

            liveStreamScreen.setPosition(new THREE.Vector3().fromArray(def.position))
            liveStreamScreen.screen.rotation.y = THREE.MathUtils.degToRad(def.rotY);

            this.liveStreamClient.setPositionalAudio(liveStreamScreen.positionalAudio);
            this.liveStreamClient.join(def.channelId);
        }
    };

    setupLogic = () => {
        const handleClickEvent = () => {
            this.controls.targetRotation = null; // if we are rotating to a movepad target, stop rotating
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.scene.children, true); //array
            if (intersects.length > 0 && intersects[0]) {
                const clickable = Clickable.clickables.get(intersects[0].object.uuid);
                if (clickable) clickable.handleClicked();
            }
        };

        let storedDraggable = null;

        const handlePointerDownEvent = (e) => {
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.scene.children, true);
            if (intersects.length > 0 && intersects[0]) {
                const draggable = Draggable.draggables.get(intersects[0].object.uuid);
                if (draggable) {
                    e.stopPropagation();
                    //todo - chris needed to disable this - possibly an oversight
                    // whiteboard.isDrawing = false;
                    this.dragging = true;
                    storedDraggable = draggable;
                }
            }
        };

        // todo - check back on this - currently disabled as we are not using postits - also! - it seems to block some movement of avatar
        // using a plane to project the drags onto. This is not ideal and could be more
        // performant but it'll do for prototype stage
        const planes = {};

        // if (whiteboardsOn) {
        // function addPlane(id, x, y, z, w, h, r) {
            // const geo = new THREE.PlaneBufferGeometry(w, h);
            // planes[id] = new THREE.Mesh(geo, new THREE.MeshBasicMaterial({ color: "red", visible: false }));
            // planes[id].position.set(x, y, z);
            // planes[id].rotateY(r * THREE.Math.DEG2RAD);
        // }

        // addPlane("plane_gallery", 7.0, 1.2, 12.55, 2, 2, 180);
        // addPlane("plane_speaker", -14.99, 1.2, 2.8, 2, 2, 90);
        // addPlane("plane_lungroom", -26.1, 1.2, -10.5, 2, 2, 0);
        // addPlane("plane_lastroom", -43.0, 2.0 + 1.2, 16.1, 2, 2, 118);

        // for (const key in planes) {
            // this.scene.add(planes[key]); // for some reason raycaster only works if this is in the scene
        // }

        const checkDraggableIntersection = (raycaster) => {
            if (storedDraggable) {
                if (storedDraggable.unrestrictred) {
                    const intersects = raycaster.intersectObject(planes[storedDraggable.meta.planeId], true);
                    if (intersects.length > 0 && intersects[0]) {
                        const pos = intersects[0].point;
                        storedDraggable.handleDragged({
                            newPos: pos,
                            r: planes[storedDraggable.meta.planeId].rotation,
                        });
                    }
                } else {
                    let pos = new THREE.Vector3();

                    const maxDistance = 2.5;

                    const intersects = raycaster.intersectObjects(this.scene.children, true);
                    if (intersects.length > 0 && intersects.length > 0) {
                        if (intersects.length > 1) {
                            pos = intersects[1].point;
                            if (pos.distanceTo(this.camera.position) > maxDistance) {
                                const ray = raycaster.ray;
                                ray.at(maxDistance, pos);
                            }
                        } else {
                            const ray = raycaster.ray;
                            ray.at(maxDistance, pos);
                        }
                    }

                    storedDraggable.handleDragged(pos);
                }
            }
        };

        const checkHoverables = (raycaster) => {
            document.body.style.cursor = "default";

            const hovering = Array.from(Hoverable.hoverables)
                .map(([_name, value]) => value)
                .filter((x) => x.hovered);

            const intersects = raycaster.intersectObjects(this.scene.children, true);

            // Check if existing hovered items match anything in the intersections. If not then mark them as unhovered.
            hovering.forEach((x) => {
                if (!intersects.find((y) => y.object === x.object)) {
                    x.handleUnhover();
                }
            });

            for (let i = 0; i < intersects.length; i++) {
                const intersect = intersects[i];
                if (i > 1) return; // NOTE: the whiteboards always seem to be the 2nd item
                const hoverable = Hoverable.hoverables.get(intersect.object.uuid);
                if (hoverable) {
                    hoverable.handleHovered();
                }
            }
        };

        const updateMouse = (e, x, y) => {
            this.mouse.x = (x / window.innerWidth) * 2 - 1;
            this.mouse.y = -(y / window.innerHeight) * 2 + 1;

            this.raycaster.setFromCamera(this.mouse, this.camera);
            checkHoverables(this.raycaster);

            if (!this.dragging) return;

            e.stopPropagation();

            //todo - chris needed to disable this - possibly an oversight
            // whiteboard.isDrawing = false;
            this.raycaster.setFromCamera(this.mouse, this.camera);
            checkDraggableIntersection(this.raycaster);
        };

        const handlePointerMoveEvent = (e) => {
            updateMouse(e, e.clientX, e.clientY);
        };

        const handleTouchMoveEvent = (e) => {
            const ex = e.changedTouches[0].pageX;
            const ey = e.changedTouches[0].pageY;
            updateMouse(e, ex, ey);
        };

        const handlePointerUpEvent = () => {
            this.dragging = false;
        };

        const handleTouchStartEvent = (e) => {
            const ex = e.changedTouches[0].pageX;
            const ey = e.changedTouches[0].pageY;
            updateMouse(e, ex, ey);
            handleClickEvent();
        };

        this.renderer.domElement.addEventListener("click", handleClickEvent, true);
        this.renderer.domElement.addEventListener("touchstart", handleTouchStartEvent, true);
        this.renderer.domElement.addEventListener("pointerdown", handlePointerDownEvent, true);
        this.renderer.domElement.addEventListener("pointermove", handlePointerMoveEvent, true);
        this.renderer.domElement.addEventListener("touchmove", handleTouchMoveEvent, false);
        this.renderer.domElement.addEventListener("pointerup", handlePointerUpEvent, true);
        this.renderer.domElement.addEventListener("touchend", handlePointerUpEvent, true);
    };
}


function convertFromEulerArray(rotationArr){
   return new THREE.Euler(
       (rotationArr[0] || 0) * THREE.MathUtils.DEG2RAD,
       (rotationArr[1] || 0) * THREE.MathUtils.DEG2RAD,
        (rotationArr[2] || 0) * THREE.MathUtils.DEG2RAD
   )
}
